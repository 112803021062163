.Footer_main_container {
  width: 100%;
  background: rgba(46, 1, 1, 0.904);
  display: flex;
  flex-direction: column;
}

.Footer_first_container {

  width: 95%;
  margin: auto;
  padding:30px 50px;
}

.Footer_second_container {


  display: flex;
  justify-content: space-between;
  /* gap: 0.3rem; */
}



.footer-container1 {
  /* background:pink; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 20%;
  /* margin-top: 2rem; */
  /* gap: 2rem; */
}
.footer-container2 {
  /* background:green; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  width:40%;
  /* margin-top: 2rem; */
  /* gap: 2rem; */
}
.footer-container3 {
  /* background:yellow; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 20%;
  /* margin-top: 2rem; */
  /* gap: 2rem; */
 /* background: #000; */
}



.footer-widget1 {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  /* background:green; */

}

.footer-logo {

  margin-bottom: 0.5rem;
  margin-left: 1.0rem;
 
}


.footer-social-icon {
  display: flex;
  flex-direction: column;
 



}

.footer-social-icon>span {
  color: #c4c6c9;
 text-shadow: 2px 2px 5px rgba(247, 4, 4, 0.393);;
 font-weight: 500;
 font-style: normal;
 font-size: medium;
}

.icon {

  font-size: 1.5rem;
  /* margin-top: 0rem; */
  gap: 0rem 0.2rem;
  display: flex;

}

.icon1 {

  color: #5853ff;
}

.icon2 {
  color: #82ccdd;
}

.icon3 {
  color: #eb2f06;
}




.footer-widget2 {
  /* background: green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-widget2-heading {
  /* color: #f2f4f6; */

  color: #c4c6c9;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: left;
  margin-left: 1.5rem;

}

.link {
  display: flex;

}

.link2 {

  width: 50%;
}

.link2>ul>li {
  list-style-type: none;
  /* margin: 1.5rem 0px; */
}
.linkss {

  font-size: 0.875rem;
  font-weight: 400;
  color: #f2f4f6;
  color: #c4c6c9;
  text-decoration: none;
}
.linkss:hover {

  font-size: 0.875rem;
  font-weight: 400;
  color: rgb(189, 102, 56);
  text-decoration: none;
}

.footer-widget3 {

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  
}
.footer-widget3-heading {
  color: #f2f4f6;
  color: #c4c6c9;
  font-size: 1.25rem;
  font-weight: 600;
  /* text-align: center; */
  /* margin-left: 1.5rem; */

}


.appbtn {
  display: flex;
  flex-direction: row;
/* justify-content:space-evenly; */
  
}

.download-blc-1 {
  /* background: #000; */
  /* color: #f2f4f6; */
  /* margin-top: 2rem; */
  /* border: 0.5px solid #fff; */
  /* border-radius: 0.5rem; */
  /* display: flex; */
  /* align-self: center; */
  
  padding: 0.5rem;
  /* font-size: 1.25rem; */
  /* font-weight: 600; */
  /* margin-right: 1px; */
}

.download-blc-2 {
  /* background: #000; */
  /* margin-top: 0.5rem; */
  /* border-radius: 0.5rem; */
  /* display: flex; */
  /* align-items: center; */
  /* border: 0.5px solid #fff; */
  /* color: #f2f4f6; */
  padding: 0.2rem;
  /* font-size: 1.25rem; */
  /* font-weight: 600; */
}

.copyrightContainer {
  display: flex;
  justify-content: center;
  background-color: #202020;
  background: rgba(46, 1, 1, 0.904);
  padding: 10px;

}


.copyrighText>p {
  color: #f2f4f6;

}

.copyrighTextlink {
  /* font-size: 1.2rem; */
  color: #d63031;
}