/* .pdfdata1 > div >a:hover {
 background: lightblue;
 color: "red";

} */
.tag{
    text-decoration: none;
color: rgba(0, 0, 0, 0.8);
    text-align: right;
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: 500;
}

.tag:hover{
        /* color: rgba(46, 1, 1, 0.904); */
color: rgb(189, 102, 56);
}



/* animation  */


.pdfContainer {
    height: 30px;
    /* Adjust height as needed */
    /* overflow: scroll; */
    /* Hide overflow to create the scrolling effect */
    /* margin-top:25vh; */
    animation: scrollAnimation 10s linear infinite;
    /* background-color: blue; */
    max-height: 300px;
        /* Set a maximum height for the container */
        overflow-y: calc(100vh - 10px);
        /* Add vertical scrollbar if content exceeds max height */
        padding-right: 20px;
        /* Add right padding to accommodate scrollbar width */
}
.pdfContainer.paused {
    animation-play-state: paused;
    /* Pause animation when container is hovered */
}
.pdfItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
    padding: 5px 10px;
    /* animation: scrollAnimation 20s linear infinite; */
    /* Apply animation */
}
.pdfItem:hover{
    animation-play-state: paused;
}

.pdfContent {
    display: flex;
    align-items: center;
}

.timestamp {
    color: rgba(46, 1, 1, 0.904);
    font-size: 10px;
}

@keyframes scrollAnimation {
    0% {
        transform: translateY(0);
        /* Start at the top */
    }

    100% {
        transform: translateY(calc(-100% - 10px));
        /* Move to the bottom */
    }
}