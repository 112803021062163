.OuterHeader {
  display: flex;
  /* background: rgba(46, 1, 1, 0.904); */
  background: #fafafae1;
  /* height: 4rem; */
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
}

.innerHeader {
  width: 90%;
  display: flex;
  margin: auto;
  padding: .3rem 0;
 
}

.innerHeaderList {
  margin: auto;
  
}

.links {

  text-decoration: none;
  /* color: white; */
  color:rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 500;
}

.links:hover {
  color: rgb(189, 102, 56);  
  /* color: black; */
}
.dropdown {
  position: relative;
  /* left: 100px; */
  display: inline-block;
  /* padding: 10px; */
}

.dropdown-toggle {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 500;
}

.dropdown-toggle:hover {
  color: rgb(189, 102, 56);
}



/* Dropdown Content (Hidden by Default) */
.dropdown-menu {
 
  display: none;
  position: absolute;
  left: -40px;
  /* width: 200px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 30px;
}

/* Links inside the dropdown */
.dropdown-menu .links {
color: rgba(0, 0, 0, 0.8);
  padding: 0.4rem 0.4rem;
  text-decoration: none;
  display: block;
  background: white;
  /* border-radius: .4rem; */
  font-size: 15px;
  font-weight: 500;
}

.dropdown-menu .links:hover {
  color: rgb(189, 102, 56);
  font-size: 15px;
  font-weight: 500;

}

.dropdown:hover .dropdown-menu {
  display: inline;
  width: 200px;
}
.dropdown1{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.dropdown-menu1 {
  border-radius: 5px;
  display: none;
  position: absolute;
  left: 190px;
  top: 200px;
  width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid rgb(192, 191, 191) ;
  overflow: hidden;

  
  
}
.dropdown1:hover .dropdown-menu1 {
  display: block;
}

.dropdown2 {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.dropdown-menu2 {
  border-radius: 5px;
  display: none;
  position: absolute;
  left: 190px;
  top: 250px;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid rgb(192, 191, 191);
  overflow: hidden;



}

.dropdown2:hover .dropdown-menu2 {
  display: block;
  position: absolute;
  overflow: hidden;
}

