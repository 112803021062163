/* src/components/GalleryItem.css */
.gallery-item {
    position: relative;
    overflow: hidden;
    margin: 10px;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
}

.gallery-item:hover img {
    transform: scale(1.3);
}