.outerSidebar{
    width: 20%;
    /* border: 0.1px solid rgb(70, 70, 70); */
    /* display: flex; */
    /* flex-direction: column; */
    background: #e9e8e8;
}
.innerSidebar{
    /* height: 14rem; */
}
.innerSidebar>p{
    padding: 0.5rem 1.5rem;
    background-color: rgba(46, 1, 1, 0.85);
    color: white;
    font-size: 1rem;
   
    /* font-family: "lato" ; */
}

.innerSidebar>li{
    padding: .2rem ;

    background: #e9e8e8;
    list-style-type: none;
    padding-left: 2rem;
    text-align: justify;
    margin: 5px 1px;
}


.innerSidebar>li>a{
    
color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
}
.innerSidebar>li>a:hover {
    color: rgb(189, 102, 56);
    /* color: rgba(46, 1, 1, 0.904); */
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
}