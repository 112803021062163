.tabledata:hover {
    color: rgb(189, 102, 56);
    background: #f9f9f9;
}

.whatsappicon:hover{ 
    color: rgb(189, 102, 56);
        /* background: #f9f9f9; */
        /* background-color: blue; */
       
        
}

.shareicon:hover{
    color: rgb(189, 102, 56);

}