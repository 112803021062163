.mainContact {

    display: flex;
    overflow: scroll;
    overflow-x: hidden;
    width: 80vh;
    border: 2px solid black;
    height: 35rem;
   
}

.table {
    position: relative;
    width: inherit;
    text-align: left;
   
}

#contacthead {
    position: sticky;
    
}


th {
    /* padding: 0 0.3rem; */
    position: sticky;
    top: 0;
    background-color: rgba(46, 1, 1, 0.85);
    color: white;
    padding: 5px;
    font-weight: 500;
}

td {
    padding: 0.3rem;
}